// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable' 
import 'regenerator-runtime/runtime'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'stylesheets/application.scss'

require("jquery")
require("bootstrap")
require("packs/bootstrap-datetimepicker.min")
require("packs/bootstrap-datetimepicker.es.js")

window.jQuery = $;
window.$ = $;

$(document).on('turbolinks:load', function() {
    $("body").tooltip({ selector: '[data-bs-toggle=tooltip]' });
});

$(document).on('turbolinks:load', function() {
setTimeout(function() { $("#alert").fadeOut(500); }, 3000)
})

$(document).ready(function(){
	$(window).scroll(function () {
			if ($(this).scrollTop() > 200) {
				$('#back-to-top').fadeIn();
			} else {
				$('#back-to-top').fadeOut();
			}
		});
		// scroll body to 0px on click
		$('#back-to-top').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 400);
			return false;
		});
});


var disabledDays = [
      "2022-01-01",
      "2022-04-15",
      "2022-04-16",
      "2022-05-01",
      "2022-05-21",
      "2022-06-21",
      "2022-06-27",
      "2022-07-16",
	  "2022-08-15",
	  "2022-09-04",
	  "2022-09-16",
	  "2022-09-18",
	  "2022-09-19",
	  "2022-10-12",
	  "2022-10-31",
	  "2022-11-01",
	  "2022-12-08",
	  "2022-12-25",
	  "2023-01-01",
	  "2023-01-02",
	  "2023-04-07",
	  "2023-04-08",
	  "2023-05-01",
	  "2023-05-21",
	  "2023-06-21",
	  "2023-06-26",
	  "2023-08-15",
	  "2023-09-18",
	  "2023-09-19",
	  "2023-10-09",
	  "2023-10-27",
	  "2023-11-01",
	  "2023-12-08",
	  "2023-12-25",
	  "2024-01-01",
	  "2024-03-29",
	  "2024-03-30",
	  "2024-05-01",
	  "2024-05-21",
	  "2024-06-09",
	  "2024-06-20",
	  "2024-06-29",
	  "2024-07-16",
	  "2024-08-15",
	  "2024-09-18",
	  "2024-09-19",
	  "2024-09-20",
	  "2024-10-12",
	  "2024-10-27",
	  "2024-10-31",
	  "2024-11-01",
	  "2024-11-24",
	  "2024-12-08",
	  "2024-12-25",
	  "2025-01-01"
    ];

$(document).on('turbolinks:load', function() {
	$("#simplecalendar").datetimepicker({
		language:'es',
		format:'dd/mm/yyyy',
		weekStart: 1,
		//startDate: '01/09/2022',
  	  	//endDate: '31/12/2022',
		startView: 'month',
		viewSelect: 'month',
		minView: 2,
		maxView: 2,
		//datesDisabled: disabledDays
	});
});

$(document).on('turbolinks:load', function() {
	$("#simplemonth").datetimepicker({
		language:'es',
		format:'dd/mm/yyyy',
		weekStart: 1,
		startView: 'year',
		viewSelect: 'year',
		minView: 3,
		maxView: 3
	});
});

$(document).on('turbolinks:load', function() {
	$("#simplemonthfilter").datetimepicker({
		language:'es',
		format:'MM yyyy',
		weekStart: 1,
		startView: 'year',
		viewSelect: 'year',
		minView: 3,
		maxView: 3
	});
});

$(document).on('turbolinks:load', function() {
	$("#rangemonth1").datetimepicker({
		autoclose: true,
		language:'es',
		format:'dd/mm/yyyy',
		weekStart: 1,
		//startDate: '01/09/2022',
  	  	//endDate: '31/12/2022',
		startView: 'year',
		viewSelect: 'year',
		minView: 3,
		maxView: 3,
		startDate: $('#last_month').val()
	});
	$("#rangemonth2").datetimepicker({
		autoclose: true,
		language:'es',
		format:'dd/mm/yyyy',
		weekStart: 1,
		//startDate: '01/09/2022',
  	  	//endDate: '31/12/2022',
		startView: 'year',
		viewSelect: 'year',
		minView: 3,
		maxView: 3,
		startDate: $('#last_month').val()
	});
   $('#rangemonth1').datetimepicker().on('changeDate',function(ev){
	   $('#rangemonth2').datetimepicker('setStartDate',ev.date);
   })
   $('#rangemonth2').datetimepicker().on('changeDate',function(ev){
	   $('#rangemonth1').datetimepicker('setEndDate',ev.date);
   })
});


$(document).on('turbolinks:load', function() {
       $('#rangeday1').datetimepicker({
		   	autoclose: true,
		    todayHighlight: false,
		    language:'es',
       		format:'dd/mm/yyyy',
		   	weekStart: 1,
			startView: 'month',
			viewSelect: 'month',
			minView: 2,
			maxView: 2,
		    datesDisabled: disabledDays
       });
       $('#rangeday2').datetimepicker({
		    autoclose: true,
		   	todayHighlight: false,
		    language:'es',
			format:'dd/mm/yyyy',
		   	weekStart: 1,
			startView: 'month',
			viewSelect: 'month',
			minView: 2,
			maxView: 2,
		    datesDisabled: disabledDays
	   });
	   $('#rangeday1').datetimepicker().on('changeDate',function(ev){
		   $('#rangeday2').datetimepicker('setStartDate',ev.date);
	   })
	   $('#rangeday2').datetimepicker().on('changeDate',function(ev){
		   $('#rangeday1').datetimepicker('setEndDate',ev.date);
	   })
   });


   $(document).on('turbolinks:load', function() {
          $('#rangeday1-noholidays').datetimepicker({
   		   	autoclose: true,
   		    todayHighlight: false,
   		    language:'es',
          	format:'dd/mm/yyyy',
   		   	weekStart: 1,
   			startView: 'month',
   			viewSelect: 'month',
   			minView: 2,
   			maxView: 2,
			startDate: $('#last_month').val()
   		    //datesDisabled: disabledDays
          });
          $('#rangeday2-noholidays').datetimepicker({
   		    autoclose: true,
   		   	todayHighlight: false,
   		    language:'es',
   			format:'dd/mm/yyyy',
   		   	weekStart: 1,
   			startView: 'month',
   			viewSelect: 'month',
   			minView: 2,
   			maxView: 2,
			startDate: $('#last_month').val()
   		    //datesDisabled: disabledDays
   	   });
   	   $('#rangeday1-noholidays').datetimepicker().on('changeDate',function(ev){
   		   $('#rangeday2-noholidays').datetimepicker('setStartDate',ev.date);
   	   })
   	   $('#rangeday2-noholidays').datetimepicker().on('changeDate',function(ev){
   		   $('#rangeday1-noholidays').datetimepicker('setEndDate',ev.date);
   	   })
      });

   
   // contar días consecutivos
   window.consecutivedays = function() {
     var startDate = $('#rangeday1-noholidays').val();
     var endDate = $('#rangeday2-noholidays').val();
	 if (startDate !== "" && endDate !== "") {
		var parts = startDate.split('/'); 
		startDate = new Date(parts[2], parts[1]-1, parts[0], "16", "00", "00");
		parts = endDate.split('/');
		endDate = new Date(parts[2], parts[1]-1, parts[0], "16", "00", "00");
       	let count = 0;
       	const curDate = new Date(startDate.getTime());
       	while (curDate <= endDate) {
           count++;
           curDate.setDate(curDate.getDate() + 1);
       }
	   if ($('#fake-checkbox').is(':checked') == true) {
	   	   count = count - 0.5;
	   }
	   $("#dynamic").val(count);
   	 }   
   }
   
   window.halfday = function() {
	   var aux = $("#dynamic").val();
	   if (aux !== 0 && aux !== ""){
	   		if ($('#fake-checkbox').is(':checked') == true) {
		   		$("#dynamic").val(parseInt(aux) - 0.5);
	   		}else{
				aux = parseFloat(aux) + 0.5;
	   	   		$("#dynamic").val(aux);
	   		}
		}
   }
   
window.somedays = function() {
  var startDate = $('#rangeday1').val();
  var endDate = $('#rangeday2').val();
  if (startDate !== "" && endDate !== "") {
    let count = 0;
	let holi = 0;
	let i = 0;
	var parts = startDate.split('/'); 
	startDate = new Date(parts[2], parts[1]-1, parts[0], "16", "00", "00");
	parts = endDate.split('/');
	endDate = new Date(parts[2], parts[1]-1, parts[0], "16", "00", "00");
    var curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
		var aux = [disabledDays[i], '16:00:00'].join(' ');
		var holiday = new Date(aux);
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {	
        	while (i < disabledDays.length){
				if (curDate.getTime() === holiday.getTime()){
					holi = 1;
					}
				i++;
				aux = [disabledDays[i], '16:00:00'].join(' ');
				holiday = new Date(aux);
				}
			if (holi === 0){
				count++;
			} else {
				holi = 0;
			}
			i = 0;
        }
        curDate.setDate(curDate.getDate() + 1);
    }
	$('#dynamic').val(count);
  }
}


window.contracttype = function() {
	var type = $('#contract').val();
	if (type == 2) {
		$('#part-time-days').show();
		$("#sueldo").text("Sueldo líquido Diario");
	}else{
		$('#part-time-days').hide();
		$("#sueldo").text("Sueldo líquido Mensual");
	}
}

window.benefitunlimited = function() {
	if ($('#unlimited').is(":checked")) {
		$('#rangemonth2').val('');
		$('#rangemonth2').prop( "disabled", true );
	}else{
		$('#rangemonth2').prop( "disabled", false );
	}
}